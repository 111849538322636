@tailwind base;
@tailwind components;
@tailwind utilities;

/* App.css */
.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Minimum height of the viewport */
}

.nc-Header {
  /* Header styles */
}

.nc-LayoutPage {
  flex: 1; /* Fill remaining vertical space */
  position: relative;
}

.nc-Footer {
  /* Footer styles */
  margin-top: auto; /* Push footer to the bottom */
}
