// POPPINS
@font-face {
  font-family: Poppins;
  font-weight: 300;
  src: url("fonts/Poppins/Poppins-Light.ttf");
}
@font-face {
  font-family: Poppins;
  font-weight: 400;
  src: url("fonts/Poppins/Poppins-Regular.ttf");
}
@font-face {
  font-family: Poppins;
  font-weight: 500;
  src: url("fonts/Poppins/Poppins-Medium.ttf");
}
@font-face {
  font-family: Poppins;
  font-weight: 600;
  src: url("fonts/Poppins/Poppins-SemiBold.ttf");
}
@font-face {
  font-family: Poppins;
  font-weight: 700;
  src: url("fonts/Poppins/Poppins-Bold.ttf");
}

// GRENZE
@font-face {
  font-family: Grenze;
  font-weight: 300;
  src: url("fonts/Grenze/Grenze-Light.ttf");
}
@font-face {
  font-family: Grenze;
  font-weight: 400;
  src: url("fonts/Grenze/Grenze-Regular.ttf");
}
@font-face {
  font-family: Grenze;
  font-weight: 500;
  src: url("fonts/Grenze/Grenze-Medium.ttf");
}
@font-face {
  font-family: Grenze;
  font-weight: 600;
  src: url("fonts/Grenze/Grenze-SemiBold.ttf");
}
@font-face {
  font-family: Grenze;
  font-weight: 700;
  src: url("fonts/Grenze/Grenze-Bold.ttf");
}

/*  */
:root {
  --font-display: Poppins;
  --font-body: Poppins;
}

.font-grenze {
  font-family: Grenze;
}
